import {PlaceHolder} from '../stories/PlaceHolder'
import {BannerPlaceHolder} from '../banner/PlaceHolder'
import {Container} from '@/components/container/Container'

export const DesktopPlaceholder = () => {
  return (
    <Container>
      <div className='mt-4'>
        <PlaceHolder />
      </div>
      <BannerPlaceHolder />
    </Container>
  )
}
