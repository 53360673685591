import {RectShape} from 'react-placeholder/lib/placeholders'
import styles from './styles.module.scss'

const StoryPlaceHolder = () => {
  return (
    <div className={styles['story_placeholders']}>
      <RectShape className={styles['story_placeholder']} color='#e6e6e6' />
      <RectShape className={styles['story_placeholder_text']} color='#e6e6e6' />
    </div>
  )
}

export const PlaceHolder = () => {
  return (
    <div className={styles['story_placeholder_container']}>
      {Array.from({length: 11}).map((_, index) => (
        <StoryPlaceHolder key={index} />
      ))}
    </div>
  )
}
