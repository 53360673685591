import {RectShape} from 'react-placeholder/lib/placeholders'
import styles from './styles.module.scss'

export const BannerPlaceHolder = () => (
  <div className={styles['banner_placeholders']} style={{gap: 20}}>
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
    <RectShape className={styles['banner_placeholder']} color='#e6e6e6' />
  </div>
)
